import React, { useEffect, useState, useRef, FC } from "react";
import { Link, useParams } from "react-router-dom";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useOrderDispatchContext, useOrderState } from "providers/Orders";
import { useOrderDispatch } from "providers/OrderFilter";
import { Order } from "providers/Orders/types";
import moment from "moment";
import RoleProvider, { ROLES } from "providers/RoleProvider";

import useRole from "../../hooks/useRole";
import { set_orderID } from "../../providers/OrderFilter/ordersAction";
import { getTransferUrl, getWallBlockchair } from "utils/helpers";
import useOrderEmail from "../../hooks/useOrderEmail";
import { Icon, PageWrapper } from "components/base";
import { ORDER_DETAILS_TABLE_ACCESS } from "../../services/roleService/order/orderDetails";
import { txBuyStatusInfo } from "../../utils/constants";
import TransactionLink from "../../components/modals/TransactionLink";
import plusSvg from "../../assets/img/plus.svg";
import masterSvg from "../../assets/img/payment-methods-master.svg";
import trueLayerSvg from "../../assets/img/true-layer.svg";
import appleSvg from "../../assets/img/payment-methods -apple.svg";
import visaSvg from "../../assets/img/payment-methods-visa.svg";
import openPayd from "../../assets/img/sepa.svg";
import stripe from "../../assets/img/stripe.svg";
import astropay from "../../assets/img/astropay.svg";
import Zimpler from "../../assets/img/zimpler.svg";
import ClearJunction from "../../assets/img/clear-junction.svg";
import Skrill from "../../assets/img/skrill.svg";
import Paybis from "../../assets/img/paybis.svg";
import volt from "../../assets/img/volt.svg";
import rapid from "../../assets/img/rapid.svg";
import neteller from "../../assets/img/neteller.svg";
import "./style.scss";
import Invoice from "components/Invoice";
import OrderDetailsSkeleton from "./OrderDetailsSkeleton";

const OrderDetailsNew: FC = () => {
  const { getOrderById } = useOrderDispatchContext();
  const orderDispatch = useOrderDispatch();
  const { orderDetails: order } = useOrderState();

  const [sendEmail, setSendingEmail] = useState(false);

  const { slug } = useParams<{ slug: string }>();

  const role = useRole();
  const ditailsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (slug) {
      getOrderById(slug);
      orderDispatch(set_orderID(slug));
    }
  }, [getOrderById, slug, orderDispatch]);

  const transferUrl = order?.tx_id && getTransferUrl(order.tx_id, order.asset);

  useOrderEmail(sendEmail && order !== null ? order.id : undefined);
  const reversedOrder = [...(order?.logs || [])];
  reversedOrder.reverse();

  return (
    <PageWrapper title={"Transaction Details"}>
      <div
        className="content d-flex flex-column flex-column-fluid"
        // ref={ditailsRef}
      >
        {!order ? (
          <OrderDetailsSkeleton />
        ) : (
          <div className="container" id="kt_content_container">
            <div className="d-flex flex-column">
              <div className="flex-column">
                <div className="card card-order">
                  <div className="order--header">
                    <div className="d-flex  flex-row card-details-header">
                      <div className="card-details-header-content">
                        <div className="card-details-header-content-body">
                          <span className="card-details-header-content-body-name">
                            {order?.client.name
                              ? `${order.client.name} ${order.client.surname}`
                              : "-"}
                          </span>
                        </div>
                        <div className="card-details-header-content-id">
                          <Link to={`/users/details/${order?.client.id}`}>
                            {order?.client.email}
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{ display: "flex" }}
                      className="order--header-button"
                    >
                      {role === "super_admin" && (
                        <RoleProvider roles={[ROLES.SUPER_ADMIN]}>
                          <PDFDownloadLink
                            document={<Invoice order={order as Order} />}
                          >
                            <button>
                              <img src={plusSvg} />
                              Download pdf
                            </button>
                          </PDFDownloadLink>
                        </RoleProvider>
                      )}
                      <button onClick={() => setSendingEmail(true)}>
                        <img src={plusSvg} />
                        <a
                          href={`mailto:${order?.client?.email}?subject=Orders Details`}
                        >
                          Send via E-mail
                        </a>
                      </button>
                    </div>
                  </div>
                  <div className="table-content order-wrapper" ref={ditailsRef}>
                    <table className="main-table">
                      {role && order && (
                        <tbody>
                          {ORDER_DETAILS_TABLE_ACCESS.orderId[role] && (
                            <tr className="order-details-tr">
                              <td className="td-definition">Transaction ID</td>
                              <td className="td-answer">{order?.id}</td>
                            </tr>
                          )}
                          {ORDER_DETAILS_TABLE_ACCESS.clientId[role] && (
                            <tr className="order-details-tr">
                              <td className="td-definition">Customer ID</td>
                              <td className="td-answer with-color">
                                <Link
                                  to={`/users/details/${order?.client?.id}`}
                                  style={{ padding: 0 }}
                                >
                                  {order?.client?.id}
                                </Link>
                              </td>
                            </tr>
                          )}
                          {ORDER_DETAILS_TABLE_ACCESS.orderId[role] && (
                            <tr className="order-details-tr">
                              <td className="td-definition">
                                Client Promotion ID
                              </td>
                              <td className="td-answer with-color">
                                {order?.cl_promotion?.id ? (
                                  <Link
                                    to={`/promotion/${order?.cl_promotion?.id}`}
                                  >
                                    {order?.cl_promotion?.id}
                                  </Link>
                                ) : (
                                  "-"
                                )}
                              </td>
                            </tr>
                          )}
                          {ORDER_DETAILS_TABLE_ACCESS.orderId[role] && (
                            <tr className="order-details-tr">
                              <td className="td-definition">
                                Partner Promotion ID
                              </td>
                              <td className="td-answer with-color">
                                {order?.pr_promotion?.id ? (
                                  <Link
                                    to={`/promotion/${order?.pr_promotion?.id}`}
                                  >
                                    {order?.pr_promotion?.id}
                                  </Link>
                                ) : (
                                  "-"
                                )}
                              </td>
                            </tr>
                          )}
                          {ORDER_DETAILS_TABLE_ACCESS.orderId[role] && (
                            <tr className="order-details-tr">
                              <td className="td-definition">Reference note</td>
                              <td className="td-answer">
                                {order?.merchant_id || "-"}
                              </td>
                            </tr>
                          )}
                          {ORDER_DETAILS_TABLE_ACCESS.partnerName[role] && (
                            <tr className="order-details-tr">
                              <td className="td-definition">Partner Name</td>
                              <td className="td-answer with-color">
                                <Link
                                  to={`/partners/details/${order?.partner?.id}`}
                                  style={{ padding: 0 }}
                                >
                                  {order?.partner?.name}
                                </Link>
                              </td>
                            </tr>
                          )}
                          {ORDER_DETAILS_TABLE_ACCESS.partnerId[role] && (
                            <tr className="order-details-tr">
                              <td className="td-definition">Partner ID</td>
                              <td className="td-answer with-color">
                                <Link
                                  to={`/partners/details/${order?.partner?.id}`}
                                  style={{ padding: 0 }}
                                >
                                  {order?.partner?.id}
                                </Link>
                              </td>
                            </tr>
                          )}
                          {ORDER_DETAILS_TABLE_ACCESS.partnerEmail[role] && (
                            <tr className="order-details-tr">
                              <td className="td-definition">Partner Email</td>
                              <td className="td-answer with-color">
                                <a
                                  href={`mailto:${order?.partner?.email}`}
                                  style={{ padding: 0 }}
                                >
                                  {order?.partner?.email}
                                </a>
                              </td>
                            </tr>
                          )}
                          {ORDER_DETAILS_TABLE_ACCESS.wallet[role] && (
                            <tr className="order-details-tr">
                              <td className="td-definition">Wallet</td>
                              <td className="td-answer with-color">
                                {order?.asset && order.address && (
                                  <a
                                    href={getWallBlockchair(
                                      order.asset,
                                      order.address
                                    )}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ padding: 0 }}
                                  >
                                    {order?.address}
                                  </a>
                                )}
                              </td>
                            </tr>
                          )}
                          {ORDER_DETAILS_TABLE_ACCESS.amount[role] && (
                            <tr className="order-details-tr">
                              <td className="td-definition">
                                Amount in {order?.currency}
                              </td>
                              <td className="td-answer">
                                {order?.amount} {order?.currency || "-"}
                              </td>
                            </tr>
                          )}
                          {ORDER_DETAILS_TABLE_ACCESS.amountByAsset[role] && (
                            <tr className="order-details-tr">
                              <td className="td-definition">
                                Amount in{" "}
                                {order?.asset === "TRC" ||
                                order?.asset === "ERC"
                                  ? "USDT"
                                  : order?.asset}
                              </td>
                              <td className="td-answer">
                                {`${order?.asset_quantity}  ${order?.asset}`}
                              </td>
                            </tr>
                          )}
                          {ORDER_DETAILS_TABLE_ACCESS.commission[role] && (
                            <tr className="order-details-tr">
                              <td className="td-definition">Processing Fee</td>
                              <td className="td-answer">
                                {order?.commission
                                  ? `${order?.commission} ${order.currency}`
                                  : "-"}
                              </td>
                            </tr>
                          )}
                          {ORDER_DETAILS_TABLE_ACCESS.markup[role] && (
                            <tr className="order-details-tr">
                              <td className="td-definition">
                                Partner Commission
                              </td>
                              <td className="td-answer">
                                {order?.markup
                                  ? `${order.markup} ${order?.currency}`
                                  : "-"}
                              </td>
                            </tr>
                          )}
                          {ORDER_DETAILS_TABLE_ACCESS.network_commission[
                            role
                          ] && (
                            <tr className="order-details-tr">
                              <td className="td-definition">
                                Network Commission
                              </td>
                              <td className="td-answer">
                                {order?.network && order.network_fee
                                  ? `${order.network_fee} ${order.network}`
                                  : "-"}
                              </td>
                            </tr>
                          )}
                          {ORDER_DETAILS_TABLE_ACCESS.cost_of_service[role] && (
                            <tr className="order-details-tr">
                              <td className="td-definition">Cost Of Service</td>
                              <td className="td-answer">
                                {order?.cost_of_service
                                  ? `${order?.cost_of_service} ${order?.currency}`
                                  : "-"}
                              </td>
                            </tr>
                          )}
                          {ORDER_DETAILS_TABLE_ACCESS.swaps_profit[role] && (
                            <tr className="order-details-tr">
                              <td className="td-definition">Swaps Revenue</td>
                              <td className="td-answer">
                                {order?.swaps_profit
                                  ? `${order?.swaps_profit} ${order?.currency}`
                                  : "-"}
                              </td>
                            </tr>
                          )}
                          {ORDER_DETAILS_TABLE_ACCESS.rate[role] && (
                            <tr className="order-details-tr">
                              <td className="td-definition">Rate</td>
                              <td className="td-answer">
                                {order?.spot_price || "-"} {order?.currency}
                              </td>
                            </tr>
                          )}
                          {ORDER_DETAILS_TABLE_ACCESS.price[role] && (
                            <tr className="order-details-tr">
                              <td className="td-definition">Price</td>
                              <td className="td-answer">
                                {order?.price ?? "-"}{" "}
                                {order?.price && order?.currency}
                              </td>
                            </tr>
                          )}
                          {ORDER_DETAILS_TABLE_ACCESS.wallet_exchange[role] && (
                            <tr className="order-details-tr">
                              <td className="td-definition">Wallet Exchange</td>
                              <td className="td-answer">
                                {order?.wallet_exchange || "-"}
                              </td>
                            </tr>
                          )}
                          {ORDER_DETAILS_TABLE_ACCESS.tr_status[role] && (
                            <tr className="order-details-tr">
                              <td className="td-definition">
                                Transaction Status
                              </td>

                              <td className="td-answer">
                                <div
                                  className={`tr-status user-status-td ${
                                    txBuyStatusInfo[
                                      order?.tr_status
                                        .toLowerCase()
                                        .replaceAll(" ", "_")
                                    ]?.className
                                  }`}
                                >
                                  {order?.tr_status.replace(/(^|\s)\S/g, (l) =>
                                    l.toUpperCase()
                                  )}
                                  <Icon
                                    name={
                                      txBuyStatusInfo[
                                        order?.tr_status
                                          .toLowerCase()
                                          .replaceAll(" ", "_")
                                      ]?.icon
                                    }
                                  />
                                </div>
                              </td>
                            </tr>
                          )}
                          {ORDER_DETAILS_TABLE_ACCESS.status[role] && (
                            <tr className="order-details-tr">
                              <td className="td-definition">Bank Status</td>
                              <td className="td-answer">
                                {
                                  {
                                    paid: (
                                      <div className="order-status-td order-status-td-success">
                                        Successful
                                      </div>
                                    ),
                                    failed: (
                                      <div className="order-status-td order-status-td-failed">
                                        Failed
                                      </div>
                                    ),
                                    pending: (
                                      <div className="order-status-td order-status-td-panding">
                                        Pending
                                      </div>
                                    ),
                                  }[(order?.status as string) || "success"]
                                }
                              </td>
                            </tr>
                          )}
                          {ORDER_DETAILS_TABLE_ACCESS.failed_reason[role] && (
                            <tr className="order-details-tr">
                              <td className="td-definition">Reason</td>
                              <td className="td-answer">
                                {order?.failed_reason && order?.tr_error_code}{" "}
                                {order?.failed_reason || "-"}
                              </td>
                            </tr>
                          )}
                          {ORDER_DETAILS_TABLE_ACCESS.payment_exchange[
                            role
                          ] && (
                            <tr className="order-details-tr">
                              <td className="td-definition">
                                Payment Exchange
                              </td>
                              <td className="td-answer">
                                {order?.payment_exchange || "-"}
                              </td>
                            </tr>
                          )}
                          {ORDER_DETAILS_TABLE_ACCESS.elliptic_risk_score[
                            role
                          ] && (
                            <tr className="order-details-tr">
                              <td className="td-definition">
                                Elliptic Risk Score
                              </td>
                              <td className="td-answer">
                                {order?.wallet_risk_score || "-"}
                              </td>
                            </tr>
                          )}
                          {ORDER_DETAILS_TABLE_ACCESS.elliptic_view[role] && (
                            <tr className="order-details-tr">
                              <td className="td-definition">Elliptic View</td>
                              <td className="td-answer with-color">
                                <a
                                  target="_blank"
                                  href={`${order?.elliptic_view}`}
                                  style={{ padding: 0 }}
                                  rel="noreferrer"
                                >
                                  {order?.elliptic_view}
                                </a>
                              </td>
                            </tr>
                          )}

                          {ORDER_DETAILS_TABLE_ACCESS.transaction_id[role] && (
                            <tr className="order-details-tr">
                              <td className="td-definition">Acquiring ID</td>
                              <td className="td-answer">
                                <TransactionLink
                                  trxId={order.transaction_id}
                                  bank={order.bank}
                                  id={order?.transaction_id}
                                />
                              </td>
                            </tr>
                          )}
                          {ORDER_DETAILS_TABLE_ACCESS.tx_id[role] && (
                            <tr className="order-details-tr">
                              <td className="td-definition with color">
                                <span className="td-without-hover">
                                  {" "}
                                  TX ID{" "}
                                </span>
                                {transferUrl && (
                                  <span>
                                    <a
                                      href={transferUrl}
                                      target="_blank"
                                      rel="noreferrer"
                                      style={{
                                        paddingLeft: "6px",
                                        fontSize: "10px",
                                      }}
                                    >
                                      Open in Blockchair
                                    </a>
                                  </span>
                                )}
                              </td>
                              <td
                                className={`td-answer ${
                                  order?.tx_id && "with-color"
                                }`}
                              >
                                {order?.tx_id || "-"}
                              </td>
                            </tr>
                          )}
                          {ORDER_DETAILS_TABLE_ACCESS.withdrawal[role] && (
                            <tr className="order-details-tr">
                              <td className="td-definition">Withdrawal</td>
                              <td className="td-andswer">
                                {
                                  {
                                    sending: (
                                      <div className="user-status-td transaction-status-td user-status-sending">
                                        Sending
                                      </div>
                                    ),
                                    successful: (
                                      <div className="user-status-td transaction-status-td user-status-success">
                                        Successful
                                      </div>
                                    ),
                                    not_sent: (
                                      <div className="user-status-td transaction-status-td user-status-not-send">
                                        Not Sent
                                      </div>
                                    ),
                                    pending: (
                                      <div className="user-status-td transaction-status-td user-status-pending">
                                        Pending
                                      </div>
                                    ),
                                  }[order.withdrawal || "success"]
                                }
                              </td>
                            </tr>
                          )}
                          {ORDER_DETAILS_TABLE_ACCESS.withdraw_id[role] && (
                            <tr className="order-details-tr">
                              <td className="td-definition">Withdrawal ID</td>
                              <td className="td-answer">
                                {order?.withdraw_id || "-"}
                              </td>
                            </tr>
                          )}
                          {ORDER_DETAILS_TABLE_ACCESS.withdraw_id[role] && (
                            <tr className="order-details-tr">
                              <td className="td-definition">Risk Level</td>
                              <td className="td-answer">
                                {order?.risk_level || "-"}
                              </td>
                            </tr>
                          )}
                          {ORDER_DETAILS_TABLE_ACCESS.payment_method[role] && (
                            <tr className="order-details-tr">
                              <td className="td-definition">Payment Method</td>
                              <td className="td-answer">
                                {order?.bank === "true-layer" && (
                                  <div className="payment-method">
                                    <img src={trueLayerSvg} />
                                    {/* <span> True Layer</span> */}
                                  </div>
                                )}
                                {order?.payment_method || order?.bank
                                  ? ((order?.payment_method === "openPayd" ||
                                      order?.bank === "openPayd") && (
                                      <div className="payment-method">
                                        <img src={openPayd} alt="applePay" />
                                        <span>OpenPayd</span>
                                      </div>
                                    )) ||
                                    ((order?.payment_method === "stripe" ||
                                      order?.bank === "stripe") && (
                                      <div className="payment-method">
                                        <img src={stripe} alt="applePay" />
                                        <span>Stripe</span>
                                      </div>
                                    )) ||
                                    (order?.payment_method === "google" && (
                                      <div className="payment-method">
                                        <img src="" />
                                        <span> Google Pay</span>
                                      </div>
                                    )) ||
                                    ((order?.payment_method === "astropay" ||
                                      order?.bank === "astropay") && (
                                      <div className="payment-method">
                                        <img src={astropay} alt="applePay" />
                                        <span>AstroPay</span>
                                      </div>
                                    )) ||
                                    ((order?.payment_method === "zimpler" ||
                                      order?.bank === "zimpler") && (
                                      <div className="payment-method">
                                        <img
                                          src={Zimpler}
                                          alt="applePay"
                                          width={20}
                                          height={20}
                                        />
                                        <span>Zimpler</span>
                                      </div>
                                    )) ||
                                    ((order?.payment_method ===
                                      "clearJunction" ||
                                      order?.bank === "clearJunction") && (
                                      <div className="payment-method">
                                        <img
                                          src={ClearJunction}
                                          alt="applePay"
                                          width={20}
                                          height={20}
                                        />
                                        <span>Clear Junction</span>
                                      </div>
                                    )) ||
                                    ((order?.payment_method === "skrill" ||
                                      order?.bank === "skrill") && (
                                      <div className="payment-method">
                                        <img
                                          src={Skrill}
                                          alt="Skrill"
                                          width={20}
                                          height={20}
                                        />
                                        <span>Skrill</span>
                                      </div>
                                    )) ||
                                    ((order?.payment_method === "paybis" ||
                                      order?.bank === "paybis") && (
                                      <div className="payment-method">
                                        <img
                                          src={Paybis}
                                          alt="paybis"
                                          width={20}
                                          height={20}
                                        />
                                        <span>Paybis</span>
                                      </div>
                                    )) ||
                                    ((order?.payment_method === "volt" ||
                                      order?.bank === "volt") && (
                                      <div className="payment-method">
                                        <img
                                          src={volt}
                                          alt="volt"
                                          width={20}
                                          height={20}
                                        />
                                        <span>Volt</span>
                                      </div>
                                    )) ||
                                    ((order?.payment_method === "rapid" ||
                                      order?.bank === "rapid") && (
                                      <div className="payment-method">
                                        <img
                                          src={rapid}
                                          alt="Rapid Transfer"
                                          width={20}
                                          height={20}
                                        />
                                        <span>Rapid Transfer</span>
                                      </div>
                                    )) ||
                                    ((order?.payment_method === "neteller" ||
                                      order?.bank === "neteller") && (
                                      <div className="payment-method">
                                        <img
                                          src={neteller}
                                          alt="neteller"
                                          width={20}
                                          height={20}
                                        />
                                        <span>Neteller</span>
                                      </div>
                                    )) ||
                                    (order?.payment_method === "apple" && (
                                      <div className="payment-method">
                                        <img src={appleSvg} alt="applePay" />
                                        <span> Apple Pay </span>
                                      </div>
                                    )) ||
                                    (order?.client_cards?.schema === "visa" ? (
                                      <div className="payment-method">
                                        <img src={visaSvg} alt="visa" />
                                        <span> Visa</span>
                                      </div>
                                    ) : (
                                      <div className="payment-method">
                                        <img src={masterSvg} alt="master" />
                                        <span> Master Card</span>
                                      </div>
                                    )) ||
                                    "-"
                                  : "-"}
                              </td>
                            </tr>
                          )}
                          <tr className="order-details-tr">
                            <td className="td-definition">Billing Country</td>
                            <td className="td-answer">
                              {order?.client?.billing_address?.countries
                                ?.country ?? "-"}
                            </td>
                          </tr>
                          <tr className="order-details-tr">
                            <td className="td-definition">Date</td>
                            <td className="td-answer">
                              {moment(order.createdAt).format(
                                "DD MMM yyyy, hh:mm"
                              )}
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {reversedOrder.length && role !== ROLES.PARTNER ? (
          <div className="table-content">
            <span className="card-details-header-content-body-name">Logs</span>
            <table className="logs">
              <tbody>
                {reversedOrder.map((el, i, arr) => (
                  <tr className="order-details-tr" key={i}>
                    <td className="td-definition logs-question">
                      {new Date(el.createdAt).toLocaleString()}
                    </td>

                    <td className="td-answer logs-answer ">
                      {el.admin.name} changed his status from{" "}
                      {
                        arr[i + 1 === arr.length ? arr.length - 1 : i + 1].event
                          .status
                      }{" "}
                      to {arr[i].event.status} - ({el.event.comment})
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>
    </PageWrapper>
  );
};

export default OrderDetailsNew;
